import React, { useState, lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Modal from "./component/modal/Modal";
import Modall from "./component/modal/Modal";
import Appp from "./component/modal/Modal";
import LoadingSpinner from "./component/Spinner";
// import RegisterForm from "./component/signup";
// import AdminDashboard from "./component/adminPanel/dashboard";
// import ProgramDetail from "./component/adminPanel/ProgramDetail";
// import UserDash from "./component/mbaUsers/dashboard";
// import SignInForm from "./component/signin";
// import NotFound from "./component/Error";
// import ConfirmationPopup from "./component/adminPanel/test_component";
// import Landing from "./component/landing";
// import ContentLoader from "react-content-loader";

const RegisterForm = lazy(() => import("./component/signup"));
const ProgramDetail = lazy(() =>
  import("./component/adminPanel/ProgramDetail")
);
const AdminDashboard = lazy(() => import("./component/adminPanel/dashboard"));
const UserDash = lazy(() => import("./component/mbaUsers/dashboard"));
const SignInForm = lazy(() => import("./component/signin"));
const NotFound = lazy(() => import("./component/Error"));
const ConfirmationPopup = lazy(() =>
  import("./component/adminPanel/test_component")
);
const Landing = lazy(() => import("./component/landing"));
const ContentLoader = lazy(() => import("./component/contentloader"));
const Forgotpassword = lazy(() => import("./component/Forgotpassword"));
const queryClient = new QueryClient();

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<LoadingSpinner></LoadingSpinner>}>
        <div>
          <div className="App">
            <BrowserRouter>
              <Routes>
                <Route exact path="/" element={<Landing />}></Route>
                <Route
                  exact
                  path="/signin"
                  element={<SignInForm onLogin={handleLogin} />}
                />
                <Route
                  exact
                  path="/forgotpassword"
                  element={<Forgotpassword />}
                />
                <Route exact path="/example" element={<Appp />}></Route>
                <Route
                  exact
                  path="/register"
                  element={<RegisterForm onLogin={handleLogin} />}
                />
                <Route
                  exact
                  path="/admindashboard"
                  element={<AdminDashboard isLoggedIn={isLoggedIn} />}
                />
                <Route
                  exact
                  path="/mba"
                  element={
                    <div>
                      {/* <Appp></Appp> */}
                      <UserDash isLoggedIn={isLoggedIn} />
                    </div>
                  }
                />
                <Route
                  path="/programs"
                  element={<ProgramDetail isLoggedIn={isLoggedIn} />}
                />
                <Route exact path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          </div>
        </div>
      </Suspense>
    </QueryClientProvider>
  );
}

export default App;
